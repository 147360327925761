import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CircleProgressComponent, CircleProgressOptions } from 'ng-circle-progress';
import { slideInAnimation } from '../animation';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    slideInAnimation
  ]
})
export class HomeComponent implements OnInit {
  // @ViewChild('circleProgress') circleProgress: CircleProgressComponent;

  constructor(private router: Router, private elRef: ElementRef, private meta: Meta) { }

  dateOfBirth = '1999-06-30'
  destroyed: Boolean = false;
  state: any = '';

  optionsB = {
    titleFontSize: 12,
    maxPercent: 100,
    radius: 20,
    showSubtitle: false,
    showInnerStroke: false,
    outerStrokeWidth: 3,
    outerStrokeColor: '#bb0000',
    backgroundColor: '#b6b6b669',
    backgroundStrokeWidth: 0,
    backgroundPadding: 5,
    titleColor: '#350404',
    unitsColor: '#350404',
    subtitleColor: '#483500',
    subtitleFormat: false,  
    startFromZero: false,
    animationDuration: 800,
 
  }


  ngOnInit(): void {
    const color = this.getColorCode('land');    
    this.meta.updateTag({ name: 'theme-color', content: color });
    this.meta.updateTag({ name: 'msappllication-navbutton-color', content: color });
    this.meta.updateTag({ name: 'apple-mobile-web-app-capable', content: color });
    this.meta.updateTag({ name: 'apple-mobile-web-app-status-bar-style', content: color });
  }
  divakaran(value) {
    this.state += value
    if (['101001', '110001', '100001'].includes(this.state)) this.router.navigateByUrl('/about');

  };
  getColorCode(id: string): string {
    const element = this.elRef.nativeElement.querySelector(`#${id}`); // Find the element with the specified ID
    const computedStyle = window.getComputedStyle(element); // Get the computed style of the element
    const color = computedStyle.getPropertyValue('background-color'); // Get the value of the color property
    return color; // Return the color code as a string
  }
  getAge(): number {
    const today = new Date(); // Get the current date
    const birthDate = new Date(this.dateOfBirth); // Convert the date of birth string to a Date object
    let age = today.getFullYear() - birthDate.getFullYear(); // Calculate the difference in years
    const monthDiff = today.getMonth() - birthDate.getMonth(); // Calculate the difference in months
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--; // Adjust the age if the person hasn't had their birthday yet this year
    }
    return age; // Return the person's age
  }
  goToLink(url: string){
    window.open(url, "");
}
}
