import { Component, ElementRef, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  constructor(private router: Router, private elRef: ElementRef, private meta: Meta) {
   }

  ngOnInit(): void {
    const color = this.getColorCode('land');    
    this.meta.updateTag({ name: 'theme-color', content: color });
    this.meta.updateTag({ name: 'msappllication-navbutton-color', content: color });
    this.meta.updateTag({ name: 'apple-mobile-web-app-capable', content: color });
    this.meta.updateTag({ name: 'apple-mobile-web-app-status-bar-style', content: color });
    setTimeout(() => {
      this.router.navigateByUrl('/home');
    }, 5000);
  }

  divakaran() {
    this.router.navigateByUrl('/home');
  };

  getColorCode(id: string): string {
    const element = this.elRef.nativeElement.querySelector(`#${id}`); // Find the element with the specified ID
    const computedStyle = window.getComputedStyle(element); // Get the computed style of the element
    const color = computedStyle.getPropertyValue('background-color'); // Get the value of the color property
    return color; // Return the color code as a string
  }
}
