import { Component, ElementRef, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private router: Router,  private elRef: ElementRef, private meta: Meta) { }
  
  @ViewChild('land') parentDiv: ElementRef;
  @ViewChild('greeting-modal') childDiv: ElementRef;

  playerName: string;
  secret_flag: boolean = true

  ngOnInit(): void {
    if (this.secret_flag){
      document.getElementById("land").style.filter="blur(4px)";
      document.getElementById("greeting-modal").style.display="grid";
    }
    const color = this.getColorCode('land');    
    this.meta.updateTag({ name: 'theme-color', content: color });
    this.meta.updateTag({ name: 'msappllication-navbutton-color', content: color });
    this.meta.updateTag({ name: 'apple-mobile-web-app-capable', content: color });
    this.meta.updateTag({ name: 'apple-mobile-web-app-status-bar-style', content: color });
  }
  divakaran() {
    this.router.navigateByUrl('/welcome');
  };

  getColorCode(id: string): string {
    const element = this.elRef.nativeElement.querySelector(`#${id}`); // Find the element with the specified ID
    const computedStyle = window.getComputedStyle(element); // Get the computed style of the element
    const color = computedStyle.getPropertyValue('background-color'); // Get the value of the color property
    return color; // Return the color code as a string
  }

  back(){
    this.router.navigateByUrl('/home');
  }

  onSubmit() {
    if (this.playerName == 'secret code'){
      console.log(this.playerName);
      document.getElementById("land").style.filter="blur(0px)";
      document.getElementById("greeting-modal").style.display="none";
      this.secret_flag = false
    }
  }
}
