import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";

export const slideInAnimation =
    trigger('routeAnimations', [
        transition('Welcome => Home', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ transform: 'translateX(100%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
                ], { optional: true }),
                query(':leave', [
                    style({ transform: 'translateX(0%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
                ], { optional: true }),
            ])
        ]),
        // ------------------------------------------------------------------------------------------------------------------------------------------
        // transition('Home => About', [
        //     query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
        //     group([
        //         query(':enter', [
        //             style({ transform: 'translateX(100%)' }),
        //             animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
        //         ], { optional: true }),
        //         query(':leave', [
        //             style({ transform: 'translateX(0%)' }),
        //             animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
        //         ], { optional: true }),
        //     ])
        // ]),
        // transition('About => Welcome', [
        //     query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
        //     group([
        //         query(':enter', [
        //             style({ transform: 'translateX(100%)' }),
        //             animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
        //         ], { optional: true }),
        //         query(':leave', [
        //             style({ transform: 'translateX(0%)' }),
        //             animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
        //         ], { optional: true }),
        //     ])
        // ]),
        // ------------------------------------------------------------------------------------------------------------------------------------------
        transition('Home => Welcome', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ transform: 'translateX(-100%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
                ], { optional: true }),
                query(':leave', [
                    style({ transform: 'translateX(0%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' }))
                ], { optional: true }),
            ])
        ]),
        transition('Home => About', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ transform: 'translateY(-100%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' }))
                ], { optional: true }),
                query(':leave', [
                    style({ transform: 'translateY(0%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateY(100%)' }))
                ], { optional: true }),
            ])
        ]),
        transition('About => Home', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
              query(':leave', [
                style({ transform: 'translateY(0%)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateY(-100%)' }))
              ], { optional: true }),
              query(':enter', [
                style({ transform: 'translateY(100%)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' }))
              ], { optional: true }),
            ])
          ]),
        transition('About => Welcome', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ transform: 'translateY(-100%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' }))
                ], { optional: true }),
                query(':leave', [
                    style({ transform: 'translateY(0%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateY(100%)' }))
                ], { optional: true }),
            ])
          ]),
          transition('Welcome => About', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
              query(':leave', [
                style({ transform: 'translateY(0%)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateY(-100%)' }))
              ], { optional: true }),
              query(':enter', [
                style({ transform: 'translateY(100%)' }),
                animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' }))
              ], { optional: true }),
            ])
          ]),
    ]);