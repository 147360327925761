import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: '/home', data: {animation: 'home'}},
  // { path: 'welcome', component: WelcomeComponent , data: {animation: 'Welcome'} },
  { path: '', component: HomeComponent , data: {animation: 'Home'} },
  // { path: 'about', component: AboutComponent , data: {animation: 'About'} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
