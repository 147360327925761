<div class="land" id="land">
     
    <div class="container">
        <div class="download" (click)="goToLink('https://drive.google.com/file/d/1ea92ApA9g2FzmT9Y-Fo9UaYudjtas3W3/view?usp=share_link')">
            <button type="button" class="md-btn md-btn-raised md-btn ripple">Resume</button>
        </div>
        <div class="item">
            <div class="left-head">
                <div class="avatar"><img src="../../../../assets/images/myAvatar_.svg" alt=""></div>
                <div class="name">Hey I'm Justin</div>
            </div>
            <div class="personal">
                <div class="field">
                    <div class="label">Name</div> <div class="value">: Justin V T</div>
                </div>
                <div class="field">
                    <div class="label">Age</div> <div class="value">: {{getAge()}}</div>
                </div>
                <div class="field">
                    <div class="label">Job</div> <div class="value">: Software Engineer</div>
                </div>
                <div class="field">
                    <div class="label">Citizenship</div> <div class="value">: India</div>
                </div>
                <div class="field">
                    <div class="label">Residence</div> <div class="value">: Kerala</div>
                </div>
                <div class="field">
                    <div class="label">e-mail</div> <div class="value">: justinvelliyath22@gmail.com</div>
                </div>
            </div>

            <div class="skills">
                <div class="name">#skills</div>
                <div class="myskills">
                    <li><span>Google at work</span> <div><circle-progress [options]="optionsB" [percent]="100" ></circle-progress></div> </li>
                    <li><span>Python</span> <div><circle-progress [options]="optionsB" [percent]="85" ></circle-progress></div></li>
                    <li><span>Django rest framework</span> <div><circle-progress [options]="optionsB" [percent]="85" ></circle-progress></div> </li>
                    <li><span>Html , CSS</span> <div><circle-progress [options]="optionsB" [percent]="65" ></circle-progress></div> </li>
                    <li><span>Angular</span> <div><circle-progress [options]="optionsB" [percent]="50" ></circle-progress></div> </li>
                    <li><span>Laravel</span> <div><circle-progress [options]="optionsB" [percent]="50" ></circle-progress></div> </li>
                    
                </div>
            </div>

        </div>
        <div class="cent">
            <!-- <div class="circles"  (click)="divakaran(1)"></div> -->
            <div class="circles"></div>
            <div class="vertical-line"></div>
        </div>
        <div class="item">
            <div class="right-head name">Experience & Education</div>
            <div class="list">
                <ul>
                    <li class="head">2022 March - Present</li>
                        <div class="li-desc head">Software Engineer - Innovature Software Labs.</div>
                        <div class="li-desc">Focus on applying the principles of engineering to software development. Their role includes analyzing and modifying existing software as well as designing, constructing and testing end-user applications that meet user needs — all through software programming languages.</div>
                    <li class="head">2021 March - 2022 March</li>
                        <div class="li-desc head">Associate Software Engineer - Innovature Software Labs.</div>
                        <div class="li-desc">Focus on applying the principles of engineering to software development. Their role includes analyzing and modifying existing software as well as designing, constructing and testing end-user applications that meet user needs — all through software programming languages.</div>
                    <li class="head">2017 - 2020</li>
                        <div class="li-desc head">AJK college of Art's and Science - Coimbatore</div>
                        <div class="li-desc">Completed Bachelor of Computer Applications from AJK college of Art's and Science - Coimbatore, Bharathiar University</div>
                    <li class="head">2015 - 2017</li>
                        <div class="li-desc head">DHSS Thachampara</div>
                        <div class="li-desc">Completed Higher Secondary Education from Desabandu Higher Secondary School Thachampara</div>
                </ul>
            </div>
        </div>
        <!-- <div class="circles_"  (click)="divakaran(0)"></div> -->
    </div>
    <div class="footer">
        <i class="fa-brands fa-instagram cursor" (click)="goToLink('https://www.instagram.com/justinvelliyath/')"></i>
        <i class="fa-brands fa-linkedin-in cursor" (click)="goToLink('https://www.linkedin.com/in/justin-velliyath/')"></i>
        <!-- <a href=""><i class="fa-brands fa-twitter cursor"></i> </a> -->
       <i class="fa-brands fa-github cursor" (click)="goToLink('https://github.com/Justinvelliyath0')"></i>
    </div>
</div>