<div class="land" id="land" > 

    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat velit laborum voluptas tenetur eius error illum nobis inventore, facilis dolorum et, reprehenderit sunt voluptates nulla officia dignissimos eligendi itaque atque!</div>
    <!-- Modal container -->
</div>
<div class="modal-container" id="greeting-modal" style="display: grid;">
    <!-- Modal  -->
    <div class="modal">
        <h3>###Oops it's protected</h3>
        <form (submit)="onSubmit()" action="">
            <input [(ngModel)]="playerName" type="password" name="secret" placeholder="secret code">
        </form>
    </div>
</div>